@import url(https://fonts.googleapis.com/css?family=Rokkitt|Nunito|Nunito+Sans);
.nunito {
  font-family: 'Nunito', serif !important;
}

.nunitoSans {
  font-family: 'Nunito Sans', sans-serif !important;
}

.Rokkitt {
  font-family: 'Rokkitt', serif !important; 
}

html {
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* overflow: hidden; */ 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.lightText {
  color: whitesmoke;
}

.yellowLink {
  color: #FFE21F;
}

.popUpStyle {
  background: 'SteelBlue';
  font-weight: 'bold';
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#DesktopContainer {
  position: 'relative' !important; 
  min-height: 100vh !important; 
  padding-bottom: 20em !important;
}

.buttonAsLink {
  padding: 0; 
  margin: 0; 
  text-align: left; 
  border: none; 
  background: none; 
  cursor: pointer;
}
.footer {
    position: absolute !important;
    bottom: 0 !important;
    padding-top: 5em !important;
    padding-bottom: 5em !important;
    width: 100%;
    border-style: solid none none none !important; 
    border-color: #c7158580 !important;
}
#h1 {
    font-size: 4em ;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 1.5em;
    /* color: MediumVioletRed; */
}

#h2 {
    font-size: 1.7em;
    font-weight: normal;
    margin-top: 1.5em;
    color: MediumVioletRed;
}

#h1Segment {
    min-height: 600px;
    padding-top: 1em;
    box-shadow: 0px 5px 30px MediumVioletRed;
    background-image: linear-gradient(to bottom right, rgb(18, 18, 19), rgb(21, 34, 75)) !important;
}


.DesktopContainer {
    position: 'relative' !important; 
    min-height: 100vh !important; 
    padding-bottom: 20em !important;
  }
.menuItem {
    padding-top: 0 !important;
}

.menuItemBot {
    padding-top: 0 !important;
    margin-bottom: 4em;
}
button.popUp {
  background-color: tomato;
  font-weight: bold;
}

#saveBtn {
  margin-left: 0.5em;
  margin-right: 0.5em; 
}

#cardGroup {
  padding: 3em;
}

button.slotBtn {
  margin-top: 1em !important;
}
button.popUp {
  background-color: tomato;
  font-weight: bold;
}

.formStyle {
  padding: 2em;
}

#newBtn {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.shareForm {
    padding: 2em;
}

#shareBtn {
    margin-left: 0.5em;
    margin-right: 0.5em; 
}
#TshirtColorSelect {
    background: #ff050500;
    color: rgb(119,155,255);
}
.popUp {
    background-color: tomato!important;
    font-weight: bold!important;
}

#downloadBtn, #spinBtn {
    margin-left: 0.5em;
    margin-right: 0.5em; 
}

.canvasMenuItem {
    margin-bottom: 1em;
} 
.paintCanvas {
    position: 'absolute';
    -webkit-transform-origin: 'left top';
            transform-origin: 'left top';
}

#topMenuSegment {
    padding-top: 0;
    padding-bottom: 0;
}
.tshirtTemplateContainer {
    position: relative;
    width: 500px;
    height: 480px;
}

.tshirtTemplate {
    position: absolute;
    width: 500px;
}

.tshirtDesign {
    position: absolute;
    width: 180px;
    left: 160px;
    top: 100px;
}

.summonCard {
    width: 500px !important;
    padding-top: 2em !important;
}

@media screen and (max-width: 560px) {
    .tshirtTemplateContainer {
        position: relative;
        width: 300px;
        height: 300px;
    }
    
    .tshirtTemplate {
        position: absolute;
        width: 300px;
    }
    
    .tshirtDesign {
        position: absolute;
        width: 100px;
        left: 100px;
        top: 60px;
    }
    
    .summonCard {
        width: 300px !important;
    }
  }
