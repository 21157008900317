#h1 {
    font-size: 4em ;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 1.5em;
    /* color: MediumVioletRed; */
}

#h2 {
    font-size: 1.7em;
    font-weight: normal;
    margin-top: 1.5em;
    color: MediumVioletRed;
}

#h1Segment {
    min-height: 600px;
    padding-top: 1em;
    box-shadow: 0px 5px 30px MediumVioletRed;
    background-image: linear-gradient(to bottom right, rgb(18, 18, 19), rgb(21, 34, 75)) !important;
}


.DesktopContainer {
    position: 'relative' !important; 
    min-height: 100vh !important; 
    padding-bottom: 20em !important;
  }