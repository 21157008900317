.popUp {
    background-color: tomato!important;
    font-weight: bold!important;
}

#downloadBtn, #spinBtn {
    margin-left: 0.5em;
    margin-right: 0.5em; 
}

.canvasMenuItem {
    margin-bottom: 1em;
} 