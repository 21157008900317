button.popUp {
  background-color: tomato;
  font-weight: bold;
}

.formStyle {
  padding: 2em;
}

#newBtn {
  margin-left: 0.5em;
  margin-right: 0.5em;
}