@import url("https://fonts.googleapis.com/css?family=Rokkitt|Nunito|Nunito+Sans");

.nunito {
  font-family: 'Nunito', serif !important;
}

.nunitoSans {
  font-family: 'Nunito Sans', sans-serif !important;
}

.Rokkitt {
  font-family: 'Rokkitt', serif !important; 
}

html {
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* overflow: hidden; */ 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.lightText {
  color: whitesmoke;
}

.yellowLink {
  color: #FFE21F;
}

.popUpStyle {
  background: 'SteelBlue';
  font-weight: 'bold';
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#DesktopContainer {
  position: 'relative' !important; 
  min-height: 100vh !important; 
  padding-bottom: 20em !important;
}

.buttonAsLink {
  padding: 0; 
  margin: 0; 
  text-align: left; 
  border: none; 
  background: none; 
  cursor: pointer;
}