button.popUp {
  background-color: tomato;
  font-weight: bold;
}

#saveBtn {
  margin-left: 0.5em;
  margin-right: 0.5em; 
}

#cardGroup {
  padding: 3em;
}

button.slotBtn {
  margin-top: 1em !important;
}