.tshirtTemplateContainer {
    position: relative;
    width: 500px;
    height: 480px;
}

.tshirtTemplate {
    position: absolute;
    width: 500px;
}

.tshirtDesign {
    position: absolute;
    width: 180px;
    left: 160px;
    top: 100px;
}

.summonCard {
    width: 500px !important;
    padding-top: 2em !important;
}

@media screen and (max-width: 560px) {
    .tshirtTemplateContainer {
        position: relative;
        width: 300px;
        height: 300px;
    }
    
    .tshirtTemplate {
        position: absolute;
        width: 300px;
    }
    
    .tshirtDesign {
        position: absolute;
        width: 100px;
        left: 100px;
        top: 60px;
    }
    
    .summonCard {
        width: 300px !important;
    }
  }